























































































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { getConfigEnv } from '@/utils/helpers';

@Component
export default class WorkflowInputs extends Vue {
  @Prop() workflow: any;

  @Prop() conditionIndex!: number;

  get condition() {
    return this.workflow.conditions[this.conditionIndex];
  }

  get showFirstSelection() {
    return (
      !this.condition.condition && !this.condition.conditionValue
    );
  }

  get wellStateSelected() {
    return (this.condition.condition || {}).value === 'STATE_OF_WELL';
  }

  conditionFormValid = false;

  selects = [
    {
      id: 1,
      key: 'Signal',
      value: 'SIGNAL',
    },
    {
      id: 2,
      key: 'Production',
      value: 'PRODUCTION',
    },
    {
      id: 3,
      key: 'Pressure',
      value: 'PRESSURE',
    },
    {
      id: 4,
      key: 'Setpoint',
      value: 'SETPOINT',
    },
    {
      id: 5,
      key: 'State of well',
      value: 'STATE_OF_WELL',
    },
  ];

  modifierValues = [
    {
      text: 'Greater than',
      value: 'GREATER_THAN',
    },
    {
      text: 'Less than',
      value: 'LESS_THAN',
    },
    {
      text: 'Decreases at',
      value: 'DECREASES_AT',
    },
    {
      text: 'Increases at',
      value: 'INCREASES_AT',
    },
  ]

  operationalModifiers = [
    {
      text: 'Value',
      value: 'VALUE',
    },
    {
      text: 'STD dev',
      value: 'STD_DEV',
    },
    {
      text: 'Mean',
      value: 'MEAN',
    },
  ];

  getValueOptions(condition) {
    switch (condition) {
      case 'Signal':
        return getConfigEnv('WORKFLOW_SIGNALS_ARRAY').map((i) => ({
          text: i.name,
          value: i.name.replace(/ /g, '_').toUpperCase(),
        }));
      case 'Production':
        return [
          {
            text: 'Oil production',
            value: 'OIL_PRODUCTION',
          },
          {
            text: 'Gas flowrate',
            value: 'GAS_FLOWRATE',
          },
          {
            text: 'Water production',
            value: 'WATER_PRODUCTION',
          },
        ];
      case 'Pressure':
        return [
          {
            text: 'Injection pressure',
            value: 'INJECTION_PRESSURE',
          },
          {
            text: 'Static pressure',
            value: 'STATIC_PRESSURE',
          },
          {
            text: 'Casing pressure',
            value: 'CASING_PRESSURE',
          },
          {
            text: 'Water static pressure',
            value: 'WATER_STATIC_PRESSURE',
          },
        ];
      case 'Setpoint':
        return [
          {
            text: 'Well shut button',
            value: 'WELL_SHUT_BUTTON',
          },
          {
            text: 'Valve status',
            value: 'VALVE_STATUS',
          },
          {
            text: 'Arrival timer setpoint',
            value: 'ARRIVAL_TIMER_SETPOINT',
          },
          {
            text: 'Tubing pressure setpoint',
            value: 'TUBING_PRESSURE_SETPOINT',
          },
          {
            text: 'Plunger last runtime',
            value: 'PLUNGER_LAST_RUNTIME',
          },
          {
            text: 'Max flowrate',
            value: 'MAX_FLOWRATE',
          },
          {
            text: 'Min flowrate',
            value: 'MIN_FLOWRATE',
          },
          {
            text: 'Well state',
            value: 'WELL_STATE',
          },
          {
            text: 'Fillage control setpoint',
            value: 'FILLAGE_CONTROL_SETPOINT',
          },
          {
            text: 'Plunger fall delay mode',
            value: 'PLUNGER_FALL_DELAY_MODE',
          },
          {
            text: 'Tubing - line setpoint',
            value: 'TUBING_LINE_SETPOINT',
          },
          {
            text: 'Time in state',
            value: 'TIME_IN_STATE',
          },
          {
            text: 'Plunger afterflow mode',
            value: 'PLUNGER_AFTERFLOW_MODE',
          },
          {
            text: 'Flowrate setpoint',
            value: 'FLOWRATE_SETPOINT',
          },
          {
            text: 'Plunger failure setpoint',
            value: 'PLUNGER_FAILURE_SETPOINT',
          },
          {
            text: 'Low flow setpoint',
            value: 'LOW_FLOW_SETPOINT',
          },
          {
            text: 'Plunger well status',
            value: 'PLUNGER_WELL_STATUS',
          },
        ];
      case 'State of well':
        return [
          {
            text: 'Prediction',
            value: 'PREDICTION',
          },
          {
            text: 'Anomaly detection',
            value: 'ANOMALY_DETECTION',
          },
          {
            text: 'Down',
            value: 'DOWN',
          },
          {
            text: 'Failure',
            value: 'FAILURE',
          },
        ];
      default:
        return [];
    }
  }

  selectChanged(val, select) {
    if (typeof val !== 'string' ) return;
    Object.assign(this.condition, {
      conditionValue: val,
      condition: {
        text: select.key,
        value: select.value,
      },
      editable: false,
      secondSectionEditable: true,
    });
    if (this.wellStateSelected) {
      this.condition.operationalModifier = {
        text: 'Times',
        value: 'TIMES',
      };
      this.condition.valueModifier = {
        text: '',
        value: 'EQUAL_TO',
      };
      this.condition.windowValue = 'days';
    }
  }

  getLabelForValue() {
    if (
      (this.condition.operationalModifier || {}).value === 'STD_DEV'
      && (this.condition.valueModifier || {}).value !== 'DECREASES_AT'
      && (this.condition.valueModifier || {}).value !== 'INCREASES_AT'
    ) {
      return '1.0';
    }
    switch (this.condition.valueModifier.value) {
      case 'DECREASES_AT':
      case 'INCREASES_AT':
        return '%';
      default:
        return '###';
    }
  }

  saveCustom() {
    Object.assign(this.condition, {
      editable: false,
      showActions: false,
      secondSectionEditable: false,
    });
    this.$emit('close')
  }
}
